<template>
  <div id="Morecourses">
    <content-title :nav="nav"></content-title>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark">
      <el-table-column prop="start_time" label="上课时间">
      </el-table-column>
      <el-table-column prop="title" label="课程">
        <template v-slot="{ row }">
          <link-to :to="getRouter(row)">{{ row.title }}</link-to>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="用户">
        <template v-slot="{ row }">
          <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }">{{ row.map.userinfo.nick_name }}</link-to>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="状态">
        <div>待上课</div>
      </el-table-column>
    </el-table>
    <paging-fy @currentPageChange="currentPageChange" :currentPage="page" :total="total"></paging-fy>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: { firstNav: "顾问管理中心", secondNav: "业务设置", Nav: "课程表" },
      tableData: [],
      manager: JSON.parse(localStorage.getItem("managerInfo")),
      page: 1,
      total: 0
    };
  },
  mounted(){
    this.getUserAppointmentList()
  },
  methods: {
    getRouter(row){
      if(row.master_type=='venue'){
        return { name: 'venueDetails', params: { id: row.master_set_price_id } }
      }else if(row.master_type=='need_bond'){
        return { name: 'courseNeedDetails', query: { id: row.master_set_price_id } }
      }
      else{
        return { name: 'courseDetails', params: { id: row.master_set_price_id } }
      }
    },
    currentPageChange(v) {
      this.page = v
      this.getUserAppointmentList()
    },
    getUserAppointmentList() {
      this.$axios({
        url: '/user/consultantRelative/queryUserAppointment',
        params: {
          admin_id: this.manager.id,
          start_time: new Date().Format("yyyy-MM-dd") + " 00:00:00",
          pageSize: 10,
          currentPage: this.page
        },
      }).then(res => {
        this.tableData = res.data.data.rows
        this.total = res.data.data.total
      })
    },
  },
};
</script>
  
<style>
#Morecourses {
  background-color: #ffffff;
  padding: 0 20px;
}

.cell {
  text-align: center;
}
</style>
  